import { FC, useEffect, useState } from "react";
import axios from "axios";
import AktTextField from "../AtlaskitControls/AtkTextField";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AktButton from "../AtlaskitControls/AtkButton";
import googleIcon from ".././assets/images/g-normal.png";
import microsoftIcon from ".././assets/images/microsoft-avatar.png";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import nowcertsIcon from "../assets/images/nc.png";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import CommonValues from "../common/utils";
import groupImg from ".././assets/images/Group.png";

interface IProps {}

const Login: FC<IProps> = () => {
  const navigate = useNavigate();
  const [LeadGenUserName, setName] = useState("");
  const [LeadGenPassword, setPassword] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const [UserError, setUserError] = useState("");
  const [SlugURL, setURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  const [echoLoading, setEchoLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  let currentMsg = 0;
  const location = useLocation();

  useEffect(() => {
    let query = new URLSearchParams(window.location.hash);
    if (query != null && query != undefined) {
      var access_token = query.get("#access_token");
      var error_msg = query.get("error_description");

      if (error_msg != "" && error_msg != null && error_msg != undefined) {
        navigate("/");
      }

      if (access_token != "" && access_token != null && access_token != undefined) {
        setSocialLoading(true);
        localStorage.setItem("st", access_token);
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_AUTHAPI}/validateSocialLogin?`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        };
        axios(config)
          .then((response: any) => {
            localStorage.setItem("SocialLogin", "true");

            if (response.data.token == "") {
              setPasswordError("Not registered user/ unauthorized user.");
            } else {
              localStorage.setItem("igtoken", response.data.token);
              localStorage.setItem("tenantId", response.data.tenantId);
              localStorage.setItem("userName", response.data.userName);
              localStorage.setItem("RoleId", response.data.roleId);
              const isChromeExtension = localStorage.getItem("isChromeExtension") === "true" ? true : false;
              const websiteUrl = localStorage.getItem("WebsiteUrl");
              if (isChromeExtension) {
                if (websiteUrl == process.env.REACT_APP_Momentum_Url1 || websiteUrl == process.env.REACT_APP_Momentum_Url2) {
                  window.open("chrome-extension://pidaodnmlepjkmkldnfommgpckflndfg/Momentum.html");
                  localStorage.removeItem("WebsiteUrl");
                } else {
                  window.open("chrome-extension://pidaodnmlepjkmkldnfommgpckflndfg/newtab.html");
                  localStorage.removeItem("WebsiteUrl");
                }
                localStorage.setItem("isChromeExtension", "false");
              } else {
                navigate("/startuppage");
              }
            }
            setSocialLoading(false);
          })
          .catch((error: any) => {
            console.log(error);
            if (error.response.data.status == 401) {
              const protocol = window.location.protocol;
              const hostname = window.location.hostname;

              const url = `${protocol}//${hostname}/agencyonboarding#access_token=${access_token}`;

              window.location.href = url;

              // window.location.href = `${window.location.hostname}/agencyonboarding#access_token=` + access_token;
              setSocialLoading(false);
              return false;
            } else if (error.response.data.status == 409) {
              setSocialLoading(false);
              navigate("/linkuser", {
                state: {
                  access_token,
                },
              });
            } else {
              console.log(error);
              setSocialLoading(false);
            }
          });
      }
    }
    //-End of Social Connection
  }, [location.hash]);

  useEffect(() => {
    let token = localStorage.getItem("igtoken");
    if (token != null) {
      localStorage.removeItem("addObject");
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_AUTHAPI}/api/Echo/get`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      };
      setEchoLoading(true);
      axios(config)
        .then((response: any) => {
          setEchoLoading(false);
          const isChromeExtension = localStorage.getItem("isChromeExtension") === "true" ? true : false;
          const websiteUrl = localStorage.getItem("WebsiteUrl");

          if (isChromeExtension) {
            if (websiteUrl == process.env.REACT_APP_Momentum_Url1 || websiteUrl == process.env.REACT_APP_Momentum_Url2) {
              window.open("chrome-extension://pidaodnmlepjkmkldnfommgpckflndfg/Momentum.html");
            } else {
              window.open("chrome-extension://pidaodnmlepjkmkldnfommgpckflndfg/newtab.html");
            }
            localStorage.setItem("isChromeExtension", "false");
          } else {
            navigate("/startuppage");
          }
        })
        .catch((error: any) => {
          setEchoLoading(false);
          console.log(error);
          if (error.response.data.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            alert("Error while processing the request.");
          }
        });
    }
  }, [currentMsg]);

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    setUserError("");
    setPasswordError("");

    if (LeadGenUserName.trim() == "") {
      formIsValid = false;
      setName("");
      setUserError("Please enter work email");
    } else {
    }

    if (LeadGenPassword.trim() == "") {
      formIsValid = false;
      setPasswordError("Please enter password");
    } else {
    }
    return formIsValid;
  };

  const buttonClick = () => {
    if (!handleValidation()) {
      return false;
    }

    const loginReq = {
      UserName: LeadGenUserName,
      Password: LeadGenPassword,
      SlugURL: SlugURL,
    };

    var data = JSON.stringify(loginReq);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_AUTHAPI}/login`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };
    setLoading(true);
    axios(config)
      .then((response: any) => {
        if (response.data.token == "") {
          setLoading(false);
          setPasswordError("Work email or Password is incorrect.");
        } else {
          localStorage.setItem("igtoken", response.data.token);
          localStorage.setItem("tenantId", response.data.tenantId);
          localStorage.setItem("userName", response.data.userName);
          localStorage.setItem("RoleId", response.data.roleId);

          navigate("/startuppage");
        }
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.status == 401) {
          setPasswordError("Work email or Password is incorrect.");
        } else {
          alert("Error while processing the request.");
        }
      });
  };

  const onCloseCLick = () => {};

  const onChangeName = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setName(e.target.value);
  };

  const onChangePassword = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setPassword(e.target.value);
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode == 13) {
      buttonClick();
    }
  };

  const onLoginWithGoogle = () => {
    var url = window.location.href;

    window.location.href =
      process.env.REACT_APP_Auth0URL +
      "/authorize?response_type=token&client_id=" +
      process.env.REACT_APP_Auth0ClientId +
      "&connection=google-oauth2&redirect_uri=" +
      url +
      "&scope=email%20profile&prompt=login&state=login&audience=" +
      process.env.REACT_APP_Auth0URL +
      "/api/v2/";
  };
  const onLoginWithGoogleForChrome = () => {
    localStorage.setItem("isChromeExtension", "true");
    localStorage.setItem("WebsiteUrl", window.location.hostname);

    var url = window.location.href;
    window.open(
      `${process.env.REACT_APP_Auth0URL}/authorize?response_type=token&client_id=${
        process.env.REACT_APP_Auth0ClientId
      }&connection=google-oauth2&redirect_uri=${encodeURIComponent(url)}&scope=email%20profile&prompt=login&state=login&audience=${
        process.env.REACT_APP_Auth0URL
      }/api/v2/`
    );
  };
  const onLoginWithMicrosoftForChrome = () => {
    localStorage.setItem("isChromeExtension", "true");
    localStorage.setItem("WebsiteUrl", window.location.hostname);
    var url = window.location.href;
    window.open(
      process.env.REACT_APP_Auth0URL +
        "/authorize?response_type=token&client_id=" +
        process.env.REACT_APP_Auth0ClientId +
        "&connection=windowslive&redirect_uri=" +
        url +
        "&scope=email%20profile&prompt=login&state=login&audience=" +
        process.env.REACT_APP_Auth0URL +
        "/api/v2/"
    );
  };
  const onLoginWithMicrosoft = () => {
    var url = window.location.href;
    window.location.href =
      process.env.REACT_APP_Auth0URL +
      "/authorize?response_type=token&client_id=" +
      process.env.REACT_APP_Auth0ClientId +
      "&connection=windowslive&redirect_uri=" +
      url +
      "&scope=email%20profile&prompt=login&state=login&audience=" +
      process.env.REACT_APP_Auth0URL +
      "/api/v2/";
  };
  const onLoginWithNowcerts = () => {
    if (window.innerWidth > 767) {
      window.open(process.env.REACT_APP_Nowcerts_Url, "_self");
    } else {
      window.open(process.env.REACT_APP_Nowcerts_Url_For_ChromeExt, "_blank");
    }
  };
  const onShowPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const onRegisterClick = () => [navigate("/agencyonboarding")];
  const onForgotPasswordClick = () => [navigate("/forgotpassword")];
  return (
    <div className="login-page">
      <Header pagename="login" />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
        <div className="register-box ">
          {/* <div className="row mx-auto d-flex align-items-center"> */}
          <div className="row register-header mx-auto p-3">
            <div className="col-12 ">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-5 col-4   d-flex  align-items-center p-0">
                  <img src={groupImg} className="me-2" />
                  <span className="text-16">
                    <b>Login</b>
                  </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-7 col-8 sepearte-page-header-div ">
                  <span>
                    Don't have an account?{" "}
                    <a onClick={onRegisterClick} target="_blank" className=" registration-related-link">
                      Register
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {socialLoading == true ? (
            <>
              <div className=" mt-4 text-center">
                <AtkLoader /> Processing ...
              </div>
            </>
          ) : (
            <>
              <div className="row mx-auto d-flex align-items-center px-3 py-4">
                <div className=" col-4 text-start mt-3 ">
                  <label>
                    Work Email<span className="text-danger ">*</span>{" "}
                  </label>
                </div>
                <div className={UserError.length > 0 ? "col-8  validation-div mt-3" : "col-8  mt-3  "}>
                  <AktTextField
                    type="text"
                    id="txtUserName"
                    name="LeadGenUserName"
                    onChange={onChangeName}
                    value={LeadGenUserName}
                    onKeyDown={onKeyDown}
                    className="input-field"
                    placeholder="Enter work email"
                  />
                  <span className="text-danger text-12 ">{UserError}</span>
                </div>
                <div className="col-4 mt-3 text-start">
                  <label>
                    Password<span className="text-danger ">*</span>{" "}
                  </label>
                </div>
                <div className={PasswordError.length > 0 ? "col-8  position-relative  validation-div mt-3" : "col-8  position-relative mt-3 "}>
                  <AktTextField
                    type={passwordType}
                    id="txtPassword"
                    name="LeadGenPassword"
                    onChange={onChangePassword}
                    value={LeadGenPassword}
                    onKeyDown={onKeyDown}
                    className="input-field"
                    placeholder="Enter password"
                  />
                  <span className="login-eye-icon" onClick={onShowPassword}>
                    {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                  </span>{" "}
                  <span className="text-danger text-12 ">{PasswordError}</span>
                </div>
                <div className="col-12 text-center mt-3 mb-2">
                  {loading || echoLoading ? (
                    <div className="p-1">
                      <AtkLoader />{" "}
                    </div>
                  ) : (
                    <AktButton label="Sign In" onClick={buttonClick} className="pf-primary-btn w-auto" />
                  )}
                </div>
                {window.innerWidth > 767 ? (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 continue-google-div my-1">
                      <span onClick={onLoginWithGoogle} className="social-link-block">
                        <span className="me-1 ">
                          <img src={googleIcon} alt="" />
                        </span>
                        <span className=" text-14 ">
                          <span>Continue with Google</span>
                        </span>
                      </span>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 continue-microsoft-div my-1">
                      <span onClick={onLoginWithMicrosoft} className="social-link-block">
                        <span className="me-1">
                          <img src={microsoftIcon} alt="" />
                        </span>
                        <span className=" text-14 ">
                          <span>Continue with Microsoft</span>
                        </span>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 continue-google-div my-1">
                      <span onClick={onLoginWithGoogleForChrome} className="social-link-block">
                        <span className="me-1 ">
                          <img src={googleIcon} alt="" />
                        </span>
                        <span className=" text-14 ">
                          <span>Continue with Google</span>
                        </span>
                      </span>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 continue-microsoft-div my-1">
                      <span onClick={onLoginWithMicrosoftForChrome} className="social-link-block">
                        <span className="me-1">
                          <img src={microsoftIcon} alt="" />
                        </span>
                        <span className=" text-14 ">
                          <span>Continue with Microsoft</span>
                        </span>
                      </span>
                    </div>
                  </>
                )}
                <div className="col-12 text-center my-2">
                  <span onClick={onLoginWithNowcerts} className="social-link-block social-link-block-nowcerts">
                    <span className="me-1 ">
                      <img src={nowcertsIcon} alt="" />
                    </span>
                    <span className=" text-14 ">
                      <span>Continue with Nowcerts</span>
                    </span>
                  </span>
                </div>
                <div className="col-6 text-end ">
                  {/* <a target="_blank" href={process.env.REACT_APP_APPMARKETURL + "/forgotpassword"} className="  login-link">
                    Forgot password
                  </a> */}

                  <a target="_self" onClick={onForgotPasswordClick} className=" show-cursor login-link">
                    Forgot password
                  </a>
                </div>
                <div className="col-6 ">
                  {/* <a target="_blank" href={process.env.REACT_APP_APPMARKETURL + "/agencyonboarding?utm_source=ChromeExt"} className="  login-link">
                    Create an account
                  </a> */}
                  <a target="_blank" onClick={onRegisterClick} className="  login-link show-cursor">
                    Create an account
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Login;
