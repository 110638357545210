import { useRef } from "react";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import { InputSwitch } from "primereact/inputswitch";

export default function FenrisData(props: any) {
  const toast: any = useRef("");
  return (
    <>
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Name</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisname}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">

          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive1}
            onChange={(e) => {
              props.onNameChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisname === null ||
                props.fenrisname == ""
                ? true
                : false
            }
          />
        </div>}
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Address Line 1</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisaddressLine1}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive2}
            onChange={(e) => {
              props.onAddressLine1Change(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisaddressLine1 === null ||
                props.fenrisaddressLine1 == ""
                ? true
                : false
            }
          />
        </div>
      }
      <div className="col-lg-3 col-md-3 col-sm-12 my-2">
        <label>City</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenriscity}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive3}
            onChange={(e) => {
              props.onCityChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenriscity === null ||
                props.fenriscity == ""
                ? true
                : false
            }
          />{" "}
        </div>
      }
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>State</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisstate}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive4}
            onChange={(e) => {
              props.onStateChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisstate === null ||
                props.fenrisstate == ""
                ? true
                : false
            }
          />
        </div>
      }
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Zip Code</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenriszipCode}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive5}
            onChange={(e) => {
              props.onZipCodeChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenriszipCode === null ||
                props.fenriszipCode == ""
                ? true
                : false
            }
          />
        </div>
      }
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Phone Number</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisphoneNumber}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive7}
            onChange={(e) => {
              props.onPhoneNumberChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisphoneNumber === null ||
                props.fenrisphoneNumber == ""
                ? true
                : false
            }
          />{" "}
        </div>
      }
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Estimated Employees</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisemployees}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive8}
            onChange={(e) => {
              props.onEmployeeChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisemployees === null ||
                props.fenrisemployees == ""
                ? true
                : false
            }
          />{" "}
        </div>
      }
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Estimated Payroll</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisestimatedPayroll}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive10}
            onChange={(e) => {
              props.onEstimatedPayrollChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisestimatedPayroll === null ||
                props.fenrisestimatedPayroll == ""
                ? true
                : false
            }
          />{" "}
        </div>
      }

      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Estimated Revenue</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisrevenue}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive11}
            onChange={(e) => {
              props.onRevenueChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisrevenue === null ||
                props.fenrisrevenue == ""
                ? true
                : false
            }
          />{" "}
        </div>
      }
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Area Square Meters</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisareaSquareMeters}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive13}
            onChange={(e) => {
              props.onAreaSquareMetersChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisareaSquareMeters === null ||
                props.fenrisareaSquareMeters == ""
                ? true
                : false
            }
          />{" "}
        </div>
      }
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Primary Naics Code</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisprimaryNaicsCode}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive14}
            onChange={(e) => {
              props.onPrimaryNaicsCodeChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisprimaryNaicsCode === null ||
                props.fenrisprimaryNaicsCode == ""
                ? true
                : false
            }
          />{" "}
        </div>
      }

      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Primary SIC Code</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisprimarySICCode}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive15}
            onChange={(e) => {
              props.onPrimarySiccCodeChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisprimarySICCode === null ||
                props.fenrisprimarySICCode == ""
                ? true
                : false
            }
          />{" "}
        </div>
      }
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Year Established</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisyearExtablished}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive16}
            onChange={(e) => {
              props.onYearExtblishedChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisyearExtablished === null ||
                props.fenrisyearExtablished == ""
                ? true
                : false
            }
          />{" "}
        </div>
      }
      <div className="col-lg-3 col-md-3 col-sm-12 my-2 ">
        <label>Number of Locations</label>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 my-2">
        <AtkTextField
          className=""
          type="text"
          id="text"
          value={props.fenrisnumberofLocation}
          isDisabled={true}
        />
      </div>
      {props.fromHistoryPage == "fromHistoryPage" ? null :
        <div className="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center my-2 ">
          <InputSwitch
            className="d-flex align-items-center fenris-switch"
            checked={props.isActive17}
            onChange={(e) => {
              props.onNumberOfLocationChange(e);
            }}
            disabled={
              props.fromHistoryPage == "fromHistoryPage" ||
                props.fenrisnumberofLocation === null ||
                props.fenrisnumberofLocation == ""
                ? true
                : false
            }
          />{" "}
        </div>
      }
      <div className="col-lg-6 col-md-6 mt-2"></div>
    </>
  )
}