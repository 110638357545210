import { InputSwitch } from "primereact/inputswitch";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Header from "../../common/Header";
import SmartScanMiddleHeader from "./SmartScanMiddleHeader";
import SmartScanSidebar from "./SmartScanSidebar";
import Footer from "../../common/Footer";
import { Toast } from "primereact/toast";
import AtkButton from "../../AtlaskitControls/AtkButton";
import SmartScanLandingPage from "./SmartScanLandingPage";
import CommonValues from "../../common/utils";
import axios from "axios";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { Navigate, useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import AtkCheckbox from "../../AtlaskitControls/AtkCheckbox";
import { RadioButton } from "primereact/radiobutton";
import Toggle from "@atlaskit/toggle";
export default function SmartScanMainpage() {
  const navigate = useNavigate();
  const [createProspect, setCreateProspect] = useState(false);
  const [createInsured, setCreateInsured] = useState(false);
  const [createQuote, setCreateQuote] = useState(false);
  const [createQuoteForInsured, setCreateQuoteForInsured] = useState(false);
  const toast: any = useRef("");
  const [showSmartScanSecondPage, setShowSmartScanSecondPage] = useState(false);
  const [search, setSearch] = useState("");

  const [insuredSearch, setInsuredSearch] = useState("");

  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientNameError, setClientNameError] = useState("");
  const [nowcertsPolicyLoading, setNowcertsPolicyLoading] = useState(false);
  const [selectedProspect, setSelectedProspect] = useState("createNew");
  const [selectedInsured, setSelectedInsured] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [nowCertPolicyData, setNowCertPolicyData] = useState<any>([]);
  const [isExistingProspectSelected, setIsExistingProspectSelected] = useState(false);
  const [isExistingInsuredSelected, setIsExistingInsuredSelected] = useState(false);
  const [isNewInsuredSelected, setIsNewInsuredSelected] = useState(false);
  const [isNewPolicySelected, setIsNewPolicySelected] = useState(false);
  const [isExistingPolicySelected, setIsExistingPolicySelected] = useState(false);
  const [insuredClientName, setInsuredClientName] = useState("");
  const [insuredClientNameError, setInsuredClientNameError] = useState("");
  const [insuredClientId, setInsuredClientId] = useState("");
  const [selectedPolicyIds, setSelectedPolicyIds] = useState<{ id: any; index: any; code: any; clientName: any }[]>([]);
  const [policyNumber, setPolicyNumber] = useState("");
  const [lineOfBusinessName, setLineOfBusinessName] = useState("");
  const [prospectOrInsuredSelect, setProspectOrInsuredSelect] = useState("");
  useEffect(() => {
    if (search.replaceAll(" ", "") == "") {
      return;
    }
    setSearchClientLoading(true);
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(search);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [search]);

  useEffect(() => {
    if (insuredSearch.replaceAll(" ", "") == "") {
      return;
    }
    setSearchClientLoading(true);
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(insuredSearch);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [insuredSearch]);

  const handlePolicyForInsured = (event: any, value: any) => {
    // useExistingForInsured createNewPolicyForInsured
    if (value === "useExistingPolicyForInsured" && event.target.checked == true) {
      setIsExistingPolicySelected(true);
      setIsNewPolicySelected(false);
    } else if (value === "createNewPolicyForInsured" && event.target.checked == true) {
      setIsExistingPolicySelected(false);
      setIsNewPolicySelected(true);
      setSelectedPolicyIds([]);
    }

    setSelectedPolicy(value);

    if (value === "useExistingPolicyForInsured" && event.target.checked == true) {
      if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id && insuredClientName != "") {
        getNowCertsPoliciesList(insuredClientId);
      } else {
        setNowCertPolicyData([]);
      }
    } else {
      setNowCertPolicyData([]);
    }
  };
  const handleClientForInsured = (event: any, value: any) => {
    if (value === "useExistingForInsured" && event.target.checked == true) {
      setIsExistingInsuredSelected(true);
      setIsNewInsuredSelected(false);
      setCreateInsured(false);
    } else if (value === "createNewForInsured" && event.target.checked == true) {
      setIsExistingInsuredSelected(false);
      setIsNewInsuredSelected(true);
      setCreateInsured(true);
      setSelectedPolicyIds([]);
      if (isExistingPolicySelected) {
        setIsNewPolicySelected(true);
        setIsExistingPolicySelected(false);
      }
    }

    setSelectedInsured(value);
    if (value == "createNewForInsured") {
      setInsuredClientName("");
      setInsuredClientId("");
      setInsuredClientNameError("");
    }
  };

  const handleCreateQuoteToggleForInsured = () => {
    setCreateQuoteForInsured(!createQuoteForInsured);
  };
  const onClientSelectForInsured = (client: any) => {
    setInsuredSearch("");
    setInsuredClientName(client.name);
    setInsuredClientId(client.databaseId);
    setInsuredClientNameError("");
    if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id && selectedPolicy == "useExistingPolicyForInsured") {
      getNowCertsPoliciesList(client.databaseId);
    }
  };
  const onClientSearchChangeForInsured = (value: any) => {
    if (value.length > 255 || selectedInsured !== "useExistingForInsured") {
      return;
    }
    setInsuredSearch(value);
  };

  const handleClientChangeForProspect = (event: any, value: any) => {
    if (value === "useExisting" && event.target.checked == true) {
      setIsExistingProspectSelected(true);
      setCreateProspect(false);
    } else if (value === "createNew" && event.target.checked == true) {
      setCreateProspect(true);
      setIsExistingProspectSelected(false);
    }

    setSelectedProspect(value);
    if (value == "createNew") {
      setClientName("");
      setClientId("");
      setClientNameError("");
    }
  };
  const handleCreateQuoteToggleForProspect = () => {
    setCreateQuote(!createQuote);
  };
  const onClientSelectForProspect = (client: any) => {
    setSearch("");
    setClientName(client.name);
    setClientId(client.databaseId);
    setClientNameError("");
    if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id && selectedProspect == "useExisting") {
      getNowCertsPoliciesList(client.databaseId);
    }
  };
  const onClientSearchChangeForProspect = (value: any) => {
    if (value.length > 255 || selectedProspect !== "useExisting") {
      return;
    }
    setSearch(value);

    setCreateQuote(true);
  };
  const getNowCertsPoliciesList = (insuredId: string) => {
    setNowcertsPolicyLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insuredpolicies?insuredId=${insuredId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.length > 0) {
          setNowCertPolicyData(response.data);
        } else {
          setNowCertPolicyData([]);
        }
        setNowcertsPolicyLoading(false);
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                detail: "Unknown error while loading the client policy. Please try again later.",
                life: 3000,
              });
            }
          }
        }
        setNowcertsPolicyLoading(false);
      });
  };
  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                detail: "Unknown error while loading the insureds data.Please try again later",
                life: 3000,
              });
            }
          }
        }
        setSearchClientLoading(false);
      });
  };
  const onSelectPolicy = (rowData: any, index: any) => {
    return (
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            value=""
            checked={selectedPolicyIds.some((item) => item.id === rowData.databaseId)}
            onChange={() => onSelectPolicyChange(rowData, index)}
            className="show-cursor bundle-checkbox"
          />
        </label>
      </div>
    );
  };
  const onSelectPolicyChange = (rowData: any, index: any) => {
    let code = "";
    if (rowData.linesOfBusiness != null || rowData.linesOfBusiness !== undefined) {
      code = rowData.linesOfBusiness[0];
    }
    setPolicyNumber(rowData.policyNumber);
    setLineOfBusinessName(code);
    let setIndex: number;

    if (window.innerWidth > 767) {
      setIndex = index.rowIndex;
    } else {
      setIndex = index;
    }
    setSelectedPolicyIds([
      {
        id: rowData.databaseId,
        index: setIndex,
        code: code,
        clientName: rowData.clientName,
      },
    ]);
    // }
  };
  const checkValidationForProspect = () => {
    let formIsValid = true;
    if ((createProspect == false || createProspect == null) && isExistingProspectSelected == false) {
      toast.current.show({
        severity: "error",
        detail: "Please select prospect client action",
        life: 3000,
      });
      formIsValid = false;
    }
    setClientNameError("");

    if (selectedProspect == "useExisting" && clientName.length == 0) {
      setClientNameError("Please select client name");
      formIsValid = false;
    } else {
      setClientNameError("");
    }

    if (selectedProspect == "useExisting" && createQuote == false) {
      toast.current.show({
        severity: "error",
        detail: "Please select prospect quote action",
        life: 3000,
      });
      formIsValid = false;
    }

    return formIsValid;
  };
  const checkValidationForInsured = () => {
    let formIsValid = true;
    if ((createInsured == false || createInsured == null) && isExistingInsuredSelected == false) {
      toast.current.show({
        severity: "error",
        detail: "Please select Insured client action",
        life: 3000,
      });
      formIsValid = false;
    }
    setInsuredClientNameError("");

    if (selectedInsured == "useExistingForInsured" && insuredClientName.length == 0) {
      setInsuredClientNameError("Please select client name");
      formIsValid = false;
    } else {
      setInsuredClientNameError("");
    }

    if (
      selectedInsured == "useExistingForInsured" &&
      createQuoteForInsured == false &&
      isNewPolicySelected == false &&
      isExistingPolicySelected == false
    ) {
      toast.current.show({
        severity: "error",
        detail: "Please select insured quote or policy action",
        life: 3000,
      });
      formIsValid = false;
    }
    if (selectedPolicy == "useExistingPolicyForInsured" && policyNumber == "" && insuredClientName.length > 0) {
      if (toast.current) {
        toast.current.show({
          severity: "error",
          detail: "Please select existing policy",
          life: 3000,
        });
      }
      formIsValid = false;
    }
    return formIsValid;
  };
  const onNextButtonClick = () => {
    if (prospectOrInsuredSelect == "Prospect") {
      if (checkValidationForProspect()) {
        setShowSmartScanSecondPage(true);
      } else {
        setShowSmartScanSecondPage(false);
      }
    } else if (prospectOrInsuredSelect == "Insured") {
      if (checkValidationForInsured()) {
        setShowSmartScanSecondPage(true);
      } else {
        setShowSmartScanSecondPage(false);
      }
    } else {
      setShowSmartScanSecondPage(false);
      toast.current.show({
        severity: "error",
        detail: "Please select Prospect or Insured",
        life: 3000,
      });
    }
  };
  const onBackClick = () => {
    setShowSmartScanSecondPage(false);
    clearValues();
    setProspectOrInsuredSelect("");
  };
  const onProspectOrInsuredSelectChange = (value: any) => {
    setProspectOrInsuredSelect(value);
    clearValues();
    if (value == "Prospect") {
      setCreateQuote(true);
    } else {
      setCreateQuote(false);
    }
  };
  const clearValues = () => {
    setSelectedPolicy("");
    setInsuredClientName("");
    setInsuredClientId("");
    setClientName("");
    setClientId("");
    setIsNewInsuredSelected(false);
    setIsExistingInsuredSelected(false);
    setIsExistingProspectSelected(false);
    setCreateProspect(false);
    setCreateQuote(false);
    setCreateQuoteForInsured(false);
    setIsNewPolicySelected(false);
    setIsExistingPolicySelected(false);
    setClientNameError("");
    setInsuredClientNameError("");
    setSelectedPolicyIds([]);
    setSelectedProspect("");
    setSelectedInsured("");
    setCreateInsured(false);
  };
  return (
    <>
      <Toast ref={toast} />

      {showSmartScanSecondPage ? (
        <SmartScanLandingPage
          onBackClick={onBackClick}
          createProspect={createProspect}
          prospectDetails={selectedProspect == "useExisting" ? clientName : selectedProspect == "createNew" ? "Create Prospect" : ""}
          createQuote={createQuote}
          quoteDetails={createQuote == true ? "Create Quote" : ""}
          insuredDatabaseId={insuredClientId}
          prospectDatabaseId={clientId}
          createInsured={createInsured}
          insuredDetails={selectedInsured == "useExistingForInsured" ? insuredClientName : "Create Insured"}
          policyDetails={isExistingPolicySelected == true ? "Use Existing Policy" : isNewPolicySelected == true ? "Create Policy" : ""}
          createQuoteForInsured={createQuoteForInsured}
          createNewPolicyForInsured={isNewPolicySelected}
          createExistingPolicyForInsured={isExistingPolicySelected}
          quoteDetailsForInsured={createQuoteForInsured == true ? "Create Quote" : ""}
          existingPolicyId={policyNumber}
        />
      ) : (
        <>
          <div className="smartscan-main-page">
            <Header pagename="smart-scan" />
            <div className="content-wrapper ">
              <div className="row  wrapper-main-row mx-auto wrapper-below-div">
                <div className="col-12">
                  <SmartScanMiddleHeader />
                </div>
                <div className="row main-content-row mx-auto">
                  <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                    <SmartScanSidebar pagename="smart-scan" />
                  </div>

                  <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                    <div className="row  d-flex  align-items-end text-12 position-relative">
                      <div className="col-12  p-0">
                        <div className="row mx-auto d-flex  align-items-center " style={{ backgroundColor: "#f6f6f6" }}>
                          <div className="col-10">
                            <div className="scan-search-row d-flex justify-content-start">
                              <div className="d-flex flex-wrap gap-3 justify-content-start">
                                <div className="d-flex align-items-center">
                                  <RadioButton
                                    inputId="prospect"
                                    name="prospect"
                                    value="Prospect"
                                    onChange={(e) => onProspectOrInsuredSelectChange(e.value)}
                                    checked={prospectOrInsuredSelect === "Prospect"}
                                  />
                                  <label htmlFor="prospect" className="mx-2">
                                    <b>Select Prospect</b>
                                  </label>
                                </div>
                                <div className="d-flex align-items-center">
                                  <RadioButton
                                    inputId="insured"
                                    name="insured"
                                    value="Insured"
                                    onChange={(e) => onProspectOrInsuredSelectChange(e.value)}
                                    // onChange={(e) => setProspectOrInsuredSelect(e.value)}
                                    checked={prospectOrInsuredSelect === "Insured"}
                                  />
                                  <label htmlFor="insured" className="mx-2">
                                    <b>Select Insured</b>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {prospectOrInsuredSelect === "Prospect" ? (
                              <div className="row scan-search-row mx-auto ">
                                <div className="col-2 ">
                                  <div className="row text-14 mb-2 ">
                                    <p className="text-16 fw-bold">Prospect</p>
                                    <div className="col-12">
                                      <div className="p-field-checkbox d-flex align-items-center">
                                        <label htmlFor="createNew">Create New</label>
                                        <Toggle
                                          id="createNew"
                                          isChecked={createProspect}
                                          onChange={(e) => handleClientChangeForProspect(e, "createNew")}
                                        />
                                      </div>
                                      <div className="p-field-checkbox  d-flex align-items-center">
                                        <label htmlFor="useExisting">Use Existing</label>
                                        <Toggle
                                          // id="useExisting"
                                          isChecked={isExistingProspectSelected}
                                          onChange={(e) => handleClientChangeForProspect(e, "useExisting")}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-2">
                                  <div className="row text-14 mb-2">
                                    <p className="text-16 fw-bold ">Quote</p>
                                    <div className="col-12">
                                      <div className="p-field-checkbox d-flex align-items-center">
                                        <label htmlFor="createNewQuote">Create Quote</label>
                                        <Toggle id="createNewQuote" isChecked={createQuote} onChange={handleCreateQuoteToggleForProspect} />{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {selectedProspect == "useExisting" ? (
                                  <div className="col-3 position-relative mb-2 p-0">
                                    <p className="text-16 fw-bold mb-1 ">Select Prospect </p>

                                    <div className={`search-bar flex-column ${clientNameError.length > 0 ? " validation-div " : " "}`}>
                                      <AtkTextField
                                        type="text"
                                        id="client-name"
                                        className={` ${clientName.length > 0 ? "clientname-placeholder" : ""} search-area w-100 `}
                                        name="search"
                                        onChange={(e) => {
                                          onClientSearchChangeForProspect(e.target.value);
                                        }}
                                        value={search}
                                        placeholder={clientName.length > 0 ? clientName : "Enter Prospect name"}
                                        isDisabled={selectedProspect !== "useExisting"}
                                      />
                                    </div>
                                    {search.replaceAll(" ", "").length > 0 && (
                                      <div id="client-search" className=" position-absolute  ">
                                        {searchClientLoading ? (
                                          <div className="d-flex justify-content-center my-2 client-loader-div">
                                            <AtkLoader />
                                          </div>
                                        ) : searchClientList.length == 0 ? (
                                          <div style={{ padding: "5px", background: "white" }}>
                                            <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                                          </div>
                                        ) : (
                                          searchClientList.map((item: any) => (
                                            <div
                                              style={{ cursor: "pointer", width: "100%" }}
                                              onClick={() => {
                                                onClientSelectForProspect(item);
                                              }}
                                            >
                                              <a
                                                href="#"
                                                style={{
                                                  color: "black",
                                                  width: "100%",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {item.name}
                                              </a>
                                            </div>
                                          ))
                                        )}
                                      </div>
                                    )}
                                    <span className="text-danger text-12">{clientNameError}</span>
                                  </div>
                                ) : null}
                              </div>
                            ) : prospectOrInsuredSelect === "Insured" ? (
                              <div className="row scan-search-row mx-auto ">
                                <div className="col-2 ">
                                  <div className="row text-14 mb-2 ">
                                    <p className="text-16 fw-bold">Client</p>
                                    <div className="col-12">
                                      <div className="p-field-checkbox d-flex align-items-center">
                                        <label htmlFor="createNew">Create New</label>
                                        <Toggle
                                          id="createNew"
                                          isChecked={isNewInsuredSelected}
                                          onChange={(e) => handleClientForInsured(e, "createNewForInsured")}
                                        />
                                      </div>
                                      <div className="p-field-checkbox  d-flex align-items-center">
                                        <label htmlFor="useExisting">Use Existing</label>
                                        <Toggle
                                          id="useExisting"
                                          isChecked={isExistingInsuredSelected}
                                          onChange={(e) => handleClientForInsured(e, "useExistingForInsured")}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-2">
                                  <div className="row text-14 mb-2">
                                    <p className="text-16 fw-bold ">Quote</p>
                                    <div className="col-12">
                                      <div className="p-field-checkbox d-flex align-items-center">
                                        <label htmlFor="createNew">Create Quote</label>
                                        <Toggle
                                          id="useExisting"
                                          isChecked={createQuoteForInsured}
                                          onChange={handleCreateQuoteToggleForInsured}
                                        />{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-2">
                                  <div className="row text-14 mb-2">
                                    <p className="text-16 fw-bold ">Policy</p>
                                    <div className="col-12">
                                      <div className="p-field-checkbox d-flex align-items-center">
                                        <label htmlFor="createNewPolicy">Create New</label>
                                        <Toggle
                                          id="createNewPolicy"
                                          isChecked={isNewPolicySelected}
                                          onChange={(e) => handlePolicyForInsured(e, "createNewPolicyForInsured")}
                                        />{" "}
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="p-field-checkbox d-flex align-items-center">
                                        <label htmlFor="useExistingPolicy">Use Existing</label>
                                        <Toggle
                                          id="useExistingPolicy"
                                          isChecked={isExistingPolicySelected}
                                          onChange={(e) => handlePolicyForInsured(e, "useExistingPolicyForInsured")}
                                          isDisabled={selectedInsured === "createNewForInsured" ? true : false}
                                        />{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {selectedInsured == "useExistingForInsured" ? (
                                  <div className="col-3 position-relative mb-2 p-0">
                                    <p className="text-16 fw-bold mb-1 ">Select Client </p>

                                    <div className={`search-bar flex-column ${insuredClientNameError.length > 0 ? " validation-div " : " "}`}>
                                      <AtkTextField
                                        type="text"
                                        id="client-name"
                                        className={` ${insuredClientName.length > 0 ? "clientname-placeholder" : ""} search-area w-100 `}
                                        name="insuredSearch"
                                        onChange={(e) => {
                                          onClientSearchChangeForInsured(e.target.value);
                                        }}
                                        value={insuredSearch}
                                        placeholder={insuredClientName.length > 0 ? insuredClientName : "Enter client name"}
                                        isDisabled={selectedInsured !== "useExistingForInsured"}
                                      />
                                    </div>
                                    {insuredSearch.replaceAll(" ", "").length > 0 && (
                                      <div id="client-search" className=" position-absolute  ">
                                        {searchClientLoading ? (
                                          <div className="d-flex justify-content-center my-2 client-loader-div">
                                            <AtkLoader />
                                          </div>
                                        ) : searchClientList.length == 0 ? (
                                          <div style={{ padding: "5px", background: "white" }}>
                                            <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                                          </div>
                                        ) : (
                                          searchClientList.map((item: any) => (
                                            <div
                                              style={{ cursor: "pointer", width: "100%" }}
                                              onClick={() => {
                                                onClientSelectForInsured(item);
                                              }}
                                            >
                                              <a
                                                href="#"
                                                style={{
                                                  color: "black",
                                                  width: "100%",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {item.name}
                                              </a>
                                            </div>
                                          ))
                                        )}
                                      </div>
                                    )}
                                    <span className="text-danger text-12">{insuredClientNameError}</span>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-2 text-end ">
                            <AtkButton label="Next" onClick={onNextButtonClick} className="pf-secondary-btn w-100 " />
                          </div>
                        </div>
                      </div>

                      {selectedPolicy == "useExistingPolicyForInsured" && isExistingPolicySelected && insuredClientName.length > 0 ? (
                        <>
                          <p className=" policy-type-heading mt-2">Existing Policies</p>
                          <div className="col-12  history-table mt-2">
                            {CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id ? (
                              nowcertsPolicyLoading ? (
                                <div className="d-flex align-items-center justify-content-center">
                                  <AtkLoader />
                                </div>
                              ) : (
                                <DataTable
                                  value={nowCertPolicyData}
                                  paginator
                                  paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                  rows={10}
                                  selectionMode="single"
                                  selection={selectedPolicy}
                                  // onSelectionChange={(e) => {
                                  //   handleRowSelect(e);
                                  // }}
                                >
                                  <Column
                                    header=""
                                    field=""
                                    body={(rowData, rowIndex) => onSelectPolicy(rowData, rowIndex)}
                                    bodyClassName="hide-cursor"
                                  ></Column>
                                  <Column
                                    header="Title"
                                    field="linesOfBusiness[0]"
                                    body={(rowData: any) => {
                                      if (rowData.linesOfBusiness != null || rowData.linesOfBusiness !== undefined) {
                                        return rowData.linesOfBusiness[0];
                                      }
                                    }}
                                    bodyClassName="hide-cursor"
                                  ></Column>
                                  <Column
                                    header="Effective Date"
                                    field="effectiveDate"
                                    body={(rowData: any) => {
                                      if (rowData.effectiveDate != null || rowData.effectiveDate !== "") {
                                        return moment.utc(rowData.effectiveDate, "YYYY-MM-DD").format("YYYY-MM-DD");
                                      }
                                    }}
                                    bodyClassName="hide-cursor"
                                    sortable
                                  ></Column>
                                  <Column header="Policy Number" field="policyNumber" sortable bodyClassName="hide-cursor"></Column>
                                  <Column
                                    header="Total Premium"
                                    field="totalPremium"
                                    body={(rowData) => <span>${rowData.totalPremium}</span>}
                                    sortable
                                    bodyClassName="hide-cursor"
                                  ></Column>
                                </DataTable>
                              )
                            ) : null}
                          </div>{" "}
                        </>
                      ) : null}
                      {/* <div className="col-10 ">
                      </div>
                      <div className="col-2 text-end mt-3">
                        <AtkButton label="Next" onClick={onNextButtonClick} className="pf-secondary-btn w-100 my-3" />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
}
