import AtkTextField from "../../AtlaskitControls/AtkTextField";
export default function ExistingData(props: any) {
  return (
    <>
      <div className="row mt-3 d-flex align-items-center">
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Name</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.name}
            isDisabled={true}
          />
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Address Line 1</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.addressLine1}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>City</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.city}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>State</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.state}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Zip Code</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.zipCode}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Phone Number</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.phoneNumber}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Estimated Employees</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.employees}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Estimated Payroll</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.estimatedPayroll}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Estimated Revenue</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.revenue}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Area Square Meters</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.areaSquareMeters}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Primary Naics Code</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.primaryNaicsCode}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Primary SIC Code</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.primarySICCode}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Year Established</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.yearExtablished}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2 ">
          <label>Number of Locations</label>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 my-2">
          <AtkTextField
            className=""
            type="text"
            id="text"
            value={props.numberofLocation}
            isDisabled={true}
          />
        </div>
      </div>
    </>
  );
}