import { useEffect, useState, useRef } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import WidgetList from "./WidgetList";
import FinanceCompanySettings from "./FinanceCompanySettings";
import FinanceCompanyList from "./FinanceCompanyList";
import AtkButton from "../AtlaskitControls/AtkButton";
import { OverlayPanel } from "primereact/overlaypanel";
import Skip from "./Skip";
import Button from "@atlaskit/button";
import SkipThisStep from "./SkipThisStep";
import CommonValues from "../common/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { useLocation } from "react-router-dom";
const hostname = window.location.hostname;

interface ParentComponentProps {
  handleChildData: (data: string) => void;
}

export default function PrimaryAmsSetting() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [nowcertsSelected, setNowcertsSelected] = useState(false);
  const [hawksoftSelected, setHawksoftSelected] = useState(false);
  const [vertaforeSelected, setVertaforeSelected] = useState(false);
  const [showGigCode, setShowGigCode] = useState(false);
  const [showWidgetList, setShowWidgetList] = useState(false);
  const [showFinanceCompanySettings, setShowFinanceCompanySettings] = useState(false);
  const [showFinanceCompanyList, setShowFinanceCompanyList] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const skipBtnRef = useRef<OverlayPanel>(null);
  const skipThisStepBtnRef = useRef<OverlayPanel>(null);
  const [premiumSettingsCurrent, setPremiumSettingsCurrent] = useState([]);
  const [isWidgetSelected, setIsWidgetSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAllPages, setShowAllPages] = useState(true);
  const [insertpfsettings, setinsertpfsettings] = useState(false);
  const [tenantIdtopass, SetTenantIdToPass] = useState("");
  const location = useLocation();
  const hostname = window.location.hostname;
  const [credentialsUpdated, setCredentialsUpdated] = useState(false);
  useEffect(() => {
    const isSetupCompleted = localStorage.getItem("isSetupCompleted");
    if (isSetupCompleted == "true") {
      navigate("/landingpage");
    }
    if (hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2) {
      setShowFinanceCompanyList(true);
      updateTenantId(4, false, false, true);
    } else {
      setShowWidgetList(true);
    }
  }, []);

  const updateTenantId = (tenantId: any, hawksoft: any, vertafore: any, nowcerts: any) => {
    setValidationMessage("");
    if (hawksoft == true || vertafore == true || nowcerts == true) {
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/primaryams?tenantid=${tenantId}`,
        headers: {
          Authorization: `Bearer ${CommonValues.GetToken()}`,
        },
      };
      axios(config)
        .then(() => {
          localStorage.setItem("tenantId", tenantId);
          SetTenantIdToPass(tenantId);
          if ((hostname ==process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) && nowcerts == true) {
            getMomentumToken();
          }
        })
        .catch((error: any) => {
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status === 401) {
              CommonValues.Logout(navigate);
            } else {
              errorMessage = "Unknown error while setting primary AMS";
            }
          }
        });
    }
  };
  const onSkipCurrentStepButtonClick = () => {
    if (skipThisStepBtnRef.current) {
      skipThisStepBtnRef.current.hide();
    }
    if (!hawksoftSelected && !nowcertsSelected && !vertaforeSelected) {
      setValidationMessage("Primary ams cannot be empty");
    } else if (
      (nowcertsSelected || hawksoftSelected || vertaforeSelected) &&
      !showGigCode &&
      !showWidgetList &&
      !showFinanceCompanyList &&
      !showFinanceCompanySettings
    ) {
      setShowGigCode(true);
    } else if (showGigCode) {
      setShowGigCode(false);
      setShowWidgetList(true);
    } else if (showWidgetList) {
      if (isWidgetSelected) {
        setShowWidgetList(false);
        setShowFinanceCompanyList(true);
      } else {
        onOkButtonClick();
      }
    } else if (showFinanceCompanyList) {
      setShowFinanceCompanyList(false);
      if (premiumSettingsCurrent.length > 0) {
        setShowFinanceCompanySettings(true);
      } else {
        onOkButtonClick();
      }
    } else if (showFinanceCompanySettings) {
      setShowFinanceCompanySettings(false);
      onOkButtonClick();
    }
  };
  const onNextButtonClick = () => {
    if (showWidgetList) {
      if (isWidgetSelected) {
        setShowWidgetList(false);
        setShowFinanceCompanyList(true);
      } else {
        toast.current.show({ severity: "info", detail: "Please select widget", life: 3000 });
      }
    } else if (showFinanceCompanyList) {
      setShowFinanceCompanyList(false);
      if (premiumSettingsCurrent.length > 0) {
        setShowFinanceCompanySettings(true);
      } else {
        setShowAllPages(false);
        onOkButtonClick();
      }
    } else if (showFinanceCompanySettings) {
      setShowAllPages(false);
      setShowFinanceCompanySettings(false);
      onOkButtonClick();
    }
  };
  const onBackButtonClick = () => {
    if (showWidgetList) {
      navigate("/setupwizard");
    } else if (showFinanceCompanyList) {
      if (hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2) {
        setShowFinanceCompanyList(false);
        navigate("/setupwizard");
      } else {
        setShowWidgetList(true);
        setShowFinanceCompanyList(false);
      }
    } else if (showFinanceCompanySettings) {
      setShowFinanceCompanyList(true);
      setShowFinanceCompanySettings(false);
    }
  };

  const onSkipButtonClick = (e: any) => {
    skipBtnRef.current?.toggle(e);
  };
  const handleSkip = () => {
    if (skipBtnRef.current) {
      skipBtnRef.current.hide();
    }
    if (skipThisStepBtnRef.current) {
      skipThisStepBtnRef.current.hide();
    }
  };
  const onOkButtonClick = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/updatewizardsetup?IsWizardSetupDone=${true}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    setNextButtonLoading(true);
    axios(config)
      .then((response: any) => {
        if (response.data.success) {
          navigate("/landingpage");
          localStorage.setItem("isSetupCompleted", "true");
        }
        setNextButtonLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while updating wizard setup state";
          }
        } else {
          errorMessage = "Unknown error while  updating wizard setup state";
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });

        setNextButtonLoading(false);
      });
  };
  const handlePremiumFinanceCompanySelected = (updatedPremiumSettingsCurrent: any) => {
    setPremiumSettingsCurrent(updatedPremiumSettingsCurrent);
  };
  const handleWidgetSelected = (widgetSelected: any) => {
    setIsWidgetSelected(widgetSelected);
  };
  const handleCredentialsChange = (areCredentialsUpdated: any) => {
    setCredentialsUpdated(areCredentialsUpdated);
  };
  const momentumcheckValidation = () => {
    let userName = localStorage.getItem("userName");
    let formIsValid = true;
    if (userName == "" || userName == null) {
      formIsValid = false;
    }
    return formIsValid;
  };
  const getMomentumToken = () => {
    if (momentumcheckValidation()) {
      handleCredentialsChange(false);
      let userName = localStorage.getItem("userName");

      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        NCUserName: userName,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/verifyMomentumCreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          if (response.data != "" && response.data != null) {
            localStorage.setItem("NCToken", response.data.access_token);
            localStorage.setItem("NCAgencyId", response.data.agencyId);
          }
          getNowCertsAgentDetails();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
            } else {
            }
          } else {
          }
        });
    }
  };
  const getNowCertsAgentDetails = () => {
    setLoading(true);
    var token = CommonValues.GetToken();
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    let nowCertsAgencyId = localStorage.getItem("NCAgencyId") ? localStorage.getItem("NCAgencyId") : "";
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/agencyinfo?nowCertsToken=${nowCertsToken}&nowCertsAgencyId=${nowCertsAgencyId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        let agencyData = {
          agencyName: response.data.agencyName,
          agencyAddress1: response.data.addressLine1,
          agencyAddress2: response.data.addressLine2,
          agencyCity: response.data.city,
          agencyState: response.data.state,
          agencyZip: response.data.zipCode,
          agencyCountry: "USA",
          agencyPhone: response.data.phone,

        };
        onSaveAmsAgencySetting(agencyData);
      })
      .catch((error: any) => {
        setLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status == 409) {
          } else {
            errorMessage = "Unknown error while getting Nowcerts agent details";
          }
        } else {
          errorMessage = "Unknown error while getting Nowcerts agent details";
        }
      });
  };
  const onSaveAmsAgencySetting = (agencyData: any) => {
    const token = CommonValues.GetToken();
    var data = {
      CompanyId: agencyData.CompanyId,
      AgencyName: agencyData.agencyName,
      Country: agencyData.agencyCountry,
      Address1: agencyData.agencyAddress1,
      Address2: agencyData.agencyAddress2,
      State: agencyData.agencyState,
      Zip: agencyData.agencyZip,
      City: agencyData.agencyCity,
      Phone: agencyData.agencyPhone,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        setLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 404) {
          } else {
          }
        }
      });
  };
  return (
    <div className="startup-page">
      <Toast ref={toast} />
      <Header pagename="setup-start" />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center setup-landing-page">
        <div className="row mx-auto wrapper-below-div setup-height-div px-3 ">
          <div className="col  bg-light rounded  position-relative setup-right-side">
            {showAllPages ? (
              <>
                {showWidgetList ? (
                  <>
                    <h4 className="text-dark py-2">Are you interested in which widget?</h4>
                    <WidgetList onWidgetSelectedUpdate={handleWidgetSelected} />
                  </>
                ) : null}

                {showFinanceCompanyList ? (
                  <>
                    <h4 className="text-dark py-2">Please select premium finance companies</h4>
                    <FinanceCompanyList onPremiumSettingsUpdate={handlePremiumFinanceCompanySelected} />
                  </>
                ) : null}

                {showFinanceCompanySettings ? (
                  <>
                    <h4 className="text-dark py-2">Please provide credentials</h4>
                    <FinanceCompanySettings />
                  </>
                ) : null}
              </>
            ) : null}
            <div className="col-12 text-end  setup-btn-position">
              <AtkButton label="Back" onClick={onBackButtonClick} className="pf-secondary-btn w-auto me-2 " />

              <Button onClick={(e: any) => onSkipButtonClick(e)} className="pf-secondary-btn w-auto me-2" shouldFitContainer>
                Skip
              </Button>

              {nextButtonLoading ? <AtkLoader /> : <AtkButton label="Next" onClick={onNextButtonClick} className="pf-primary-btn w-auto " />}
            </div>

            <OverlayPanel ref={skipBtnRef} className="menu-panel ">
              <Skip onCancelButtonClick={handleSkip} />
            </OverlayPanel>
            <OverlayPanel ref={skipThisStepBtnRef} className="menu-panel ">
              <SkipThisStep onCancelButtonClick={handleSkip} onSkipCurrentStepButtonClick={onSkipCurrentStepButtonClick} />
            </OverlayPanel>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
